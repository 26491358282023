<template>
    <span>
      {{ label }}
    </span>
  </template>
  
  <script>
  import Vue from 'vue';
  import { setReadOnlyIfNotEditable } from './util.js';
  
  export default Vue.extend({
    name: 'StatusCellRenderer',
    data: function() {
    
    },
    computed: {
      label() {
        return this.params?.value == null
          ? this.$t('string.unknown')
          : this.params.value 
          ? this.$t('string.active')
          : this.$t('string.inactive');

      }
    },
    beforeMount() {
      setReadOnlyIfNotEditable(this.params);
    }
  })
  </script>