

<template>
  <div>
    <!-- <AccessPolicySelectorModal :show.sync="selectorShow" @ok="selectorOk" :multiple="false" /> -->
    <!-- access policy selector -->
    <GenericSelectorModalForAdmin v-if="selectorShow"
      :show.sync="selectorShow" 
      :entityService="accessPolicyUtil"
      :preselected="value? value.uuId : null"
      entity="ACCESS_POLICY"
      nonAdmin
      singleSelection
      @ok="selectorOk"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { accessPolicyUtil } from '@/views/management/script/accessPolicy';

export default Vue.extend({
  name: 'LocationEditor',
  components: {
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  data() {
    return {
      value: '',
      selectorShow: true
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.stopEditing();
      }, 100);
    },
    selectorOk({ details }) {
      const newAccessPolicy = details.map(i => { return { uuId: i.uuId, name: i.name}});

      if (newAccessPolicy.length === 0) {
        this.value = null;
        this.closePicker();
      }
      this.value = newAccessPolicy[0];
      this.closePicker();
    }
  },

  created() {
    this.accessPolicyUtil = accessPolicyUtil;
    this.value = this.params.value;
  },
  beforeDestroy() {
    this.accessPolicyUtil = null;
  }
})
</script>

<style lang="scss" scoped>
.edit-cell {
  background-color: var(--white);
}

.number-input {
  width: 100%;
  height: 100%;
  padding: 1px 2px;
  outline: none;
  border: none;
}

</style>