var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.selectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.selectorShow,
              entityService: _vm.accessPolicyUtil,
              preselected: _vm.value ? _vm.value.uuId : null,
              entity: "ACCESS_POLICY",
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.selectorShow = $event
              },
              ok: _vm.selectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }