<template>
  <ProjectBadgeGroupModal :show.sync="modalShow" :projectList="projectList" @ok="projectSelectorOk" @cancel="projectSelectorCancel" @projectDeleted="projectSelectorDeleted"/>
</template>

<script>
import Vue from 'vue';
// import { objectClone } from '@/helpers';

export default Vue.extend({
  name: 'ProjectsEditor',
  components: {
    ProjectBadgeGroupModal: () => import('@/components/modal/ProjectBadgeGroupModal')
  },
  data() {
    return {
      value: ''
      , modalShow: false
      , projectList: []
      
    };
  },
  computed: {
  },
  methods: {
    getValue() {
      return this.value
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    projectSelectorOk(modifiedProjectList) {
      //Check array size
      if (!this.params.value ||
          modifiedProjectList.length != this.params.value.length) {
        this.value = modifiedProjectList;
        this.closePicker();
        return;
      }

      //Check UuId
      const originProjects = this.params.value;
      const originIds = originProjects.map(i => i.uuId);
      
      let hasChanged = false;
      for (let i = 0, len = modifiedProjectList.length; i < len; i++) {
        if (!originIds.includes(modifiedProjectList[i].uuId)) {
          hasChanged = true;
          break;
        }
      }
      if (hasChanged) {
        this.value = modifiedProjectList;
      }
      this.closePicker;
    },
    projectSelectorCancel({ modifiedList=[]}={}) {
      if (modifiedList.length > 0) {
        const deletedIds = this.modifiedList.filter(i => i.action =='DELETE').map(i => i.uuId)
        const updatedList = this.modifiedList.filter(i => i.action == 'UPDATE')
        const remaining = this.value.filter(i => !deletedIds.includes(i.uuId))
        for (const item of remaining) {
          const obj = updatedList.find(i => i.uuId == item.uuId)
          if (obj == null) {
            continue
          }
          item.name = obj.name
          // item.color = obj.color
        }
        this.value = remaining
      }
      this.closePicker()
    },
    prepareProjectList() {
      if(this.value != null) {
        for (const project of this.value) {
          this.projectList.push({ uuId: project.uuId, name: project.name });
        }
      }
    },
    projectSelectorChanged({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name }});
    },
    projectSelectorDeleted(/**{ removedList=[]}={} **/) {
      
    }
    
  },

  created() {
    this.modalShow = true;
    this.value = this.params.value;
    this.prepareProjectList();
    
  }
})
</script>