<template>
  <CustomerBadgeGroupModal :show.sync="modalShow" 
    :customerList="customerList" 
    @ok="customerSelectorOk" 
    @cancel="customerSelectorCancel" 
    @customerDeleted="customerSelectorDeleted"
    :isOptional="isOptional"/>
</template>

<script>
import Vue from 'vue';
// import { objectClone } from '@/helpers';

export default Vue.extend({
  name: 'CustomersEditor',
  components: {
    CustomerBadgeGroupModal: () => import('@/components/modal/CustomerBadgeGroupModal')
  },
  data() {
    return {
      value: ''
      , modalShow: false
      , customerList: []
      , isOptional: true
    };
  },
  computed: {
  },
  methods: {
    getValue() {
      return this.value
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    customerSelectorOk(modifiedCustomerList) {
      //Check array size
      if (!this.params.value ||
          modifiedCustomerList.length != this.params.value.length) {
        this.value = modifiedCustomerList;
        this.closePicker();
        return;
      }

      //Check UuId
      const originCustomers = this.params.value;
      const originIds = originCustomers.map(i => i.uuId);
      
      let hasChanged = false;
      for (let i = 0, len = modifiedCustomerList.length; i < len; i++) {
        if (!originIds.includes(modifiedCustomerList[i].uuId)) {
          hasChanged = true;
          break;
        }
      }
      if (hasChanged) {
        this.value = modifiedCustomerList;
      }
      this.closePicker;
    },
    customerSelectorCancel({ modifiedList=[]}={}) {
      if (modifiedList.length > 0) {
        const deletedIds = this.modifiedList.filter(i => i.action =='DELETE').map(i => i.uuId)
        const updatedList = this.modifiedList.filter(i => i.action == 'UPDATE')
        const remaining = this.value.filter(i => !deletedIds.includes(i.uuId))
        for (const item of remaining) {
          const obj = updatedList.find(i => i.uuId == item.uuId)
          if (obj == null) {
            continue
          }
          item.name = obj.name
          // item.color = obj.color
        }
        this.value = remaining
      }
      this.closePicker()
    },
    prepareCustomerList() {
      if(this.value != null) {
        for (const customer of this.value) {
          this.customerList.push({ uuId: customer.uuId, name: customer.name });
        }
      }
    },
    customerSelectorChanged({ ids }) {
      // Even if the list didn't change, we replace it with the same values anyway
      // because it triggers an update in the parent grid. We do this because we could
      // be deleting an item that this row doesn't have, but another row might
      this.value = this.value.filter(i => !ids.includes(i.uuId)).map(i => { return { uuId: i.uuId, name: i.name }});
    },
    customerSelectorDeleted(/**{ removedList=[]}={}*/) {
      
    }
    
  },

  created() {
    this.modalShow = true;
    if (this.params.isOptional != null) {
      this.isOptional = this.params.isOptional;
    }
    this.value = this.params.value;
    this.prepareCustomerList();
    
  }
})
</script>