<template>
  <div class="edit-cell" @focusout="onFocusOut">
    <b-input-group class="no-focus-shadow">
      <b-input-group-prepend>
        <b-button size="sm" @focus="onFocus" @click.prevent="progressAddMinus(-1)">
          <font-awesome-icon :icon="['far', 'minus']"/>
        </b-button>
      </b-input-group-prepend>
      <b-form-input id="task-progress" size="sm" v-model="value" min="0" max="100" type="text" @focus="onFocus" @blur="progressFormat" :ref="'input'" @keydown="onKeyDown($event)"></b-form-input>
      <b-input-group-append>
        <b-button size="sm" @focus="onFocus" @click.prevent="progressAddMinus(1)">
          <font-awesome-icon :icon="['far', 'plus']"/>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import Vue from 'vue';
import { objectClone } from '@/helpers';

const KEY_ENTER = 'Enter';
const KEYCODE_BACKSPACE = 8;
const KEYCODE_DELETE = 46;
const KEYCODE_ENTER = 13;
const KEYCODE_TAB = 9;
const KEYCODE_ESCAPE = 27;
const KEYCODE_LEFT = 37;
const KEYCODE_UP = 38;
const KEYCODE_RIGHT = 39;
const KEYCODE_DOWN = 40;

export default Vue.extend({
  name: 'PlainPercentageEditor',
  data() {
    return {
      value: '',
      cancelBeforeStart: true,
      highlightAllOnFocus: true,
      stillOnFocused: false,
      compactMode: false,
      isFirstKeyEnter: false
    };
  },
  methods: {
    isPopup() {
      return false;
    },
    getValue() {
      if (this.compactMode) {
        const orgValue = this.params.value.single;
        let curValue = this.params.value.single;
        if (this.value !== null &&
            typeof this.value !== 'undefined') {
          curValue = this.value / 100;
        }

        if (this.params.value.uuId == null && curValue == 0) {
          return this.params.value;
        }

        if (orgValue == curValue) {
          return this.params.value;
        }
        const updatedValue = objectClone(this.params.value);
        updatedValue.single = curValue;
        return updatedValue;
      } else {
        if (this.value !== null && typeof this.value !== 'undefined') {
          return this.value / 100;
        }
        return this.value;
      }
    },

    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },

    setInitialState(params) {
      let startValue;
      let highlightAllOnFocus = true;

      if (params.key === KEY_ENTER || params.charPress == null) {
        //Enter key pressed or click to edit
        this.isFirstKeyEnter = true;
      }

      if (params.keyPress === KEYCODE_BACKSPACE || params.keyPress === KEYCODE_DELETE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (params.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = params.charPress;
        highlightAllOnFocus = false;
      } else {
        const value = this.compactMode? objectClone(params.value.single) : params.value;
        if (typeof value !== 'undefined') {
          startValue = value * 100;
        }
        else {
          startValue = value;
        }
      }

      this.value = startValue;
      this.highlightAllOnFocus = highlightAllOnFocus;
    },

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd() {
      return this.value > 100;
    },

    onKeyDown(event) {
      const keyCode = this.getCharCodeFromEvent(event);
      const api = this.params.api;
      if (KEYCODE_ENTER == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          this.moveUp(api, this.params.rowIndex, this.params.column.colId);
        } else {
          this.moveDown(api, this.params.rowIndex, this.params.column.colId);
        }
      } else if (KEYCODE_TAB == keyCode) {
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          api.tabToPreviousCell();
        } else {
          api.tabToNextCell();
        }
      } else if (this.isNavigationKey(event) && !this.isFirstKeyEnter) {
        event.stopPropagation();
        event.preventDefault();
        if (KEYCODE_LEFT == keyCode ) {
          api.tabToPreviousCell();
        }
        else if (KEYCODE_RIGHT == keyCode) {
          api.tabToNextCell();
        }
        else if (KEYCODE_UP == keyCode) {
          this.moveUp(api, this.params.rowIndex, this.params.column.colId);
        }
        else if (KEYCODE_DOWN == keyCode) {
          this.moveDown(api, this.params.rowIndex, this.params.column.colId);
        }
        return;
      }

      if (keyCode == KEYCODE_ESCAPE) {
        this.value = this.compactMode? this.params.value.single : this.params.value;
        
        setTimeout(() => {
          this.params.api.stopEditing();
        }, 100);
      } else {
        if (this.deleteOrBackspace(event)) {
          event.stopPropagation();
          return;
        }

        if (
          !this.finishedEditingPressed(event) &&
          !(this.isKeyPressedNumeric(event) || this.isLeftOrRight(event))
        ) {
          if (event.preventDefault) event.preventDefault();
        }
      }
    },

    getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    },

    isCharNumeric(charStr) {
      return /[\d.]/.test(charStr);
    },

    isKeyPressedNumeric(event) {
      const charStr = event.key;
      return this.isCharNumeric(charStr);
    },

    finishedEditingPressed(event) {
      const charCode = this.getCharCodeFromEvent(event);
      return charCode === KEYCODE_ENTER || charCode === KEYCODE_TAB;
    },

    deleteOrBackspace(event) {
      return (
        [KEYCODE_DELETE, KEYCODE_BACKSPACE].indexOf(this.getCharCodeFromEvent(event)) >
        -1
      );
    },

    isLeftOrRight(event) {
      return [37, 39].indexOf(this.getCharCodeFromEvent(event)) > -1;
    },
    progressFormat() {
      if(isNaN(this.value)) {
        this.value = 0;
      }
      const value = parseFloat(this.value);
      if(value < 0) {
        this.value = 0;
      } else if(value > 100) {
        this.value = 100;
      } else {
        this.value = value;
      }
    },
    progressAddMinus(delta) {
      if(isNaN(this.value)) {
        this.value = 0;
      }
      const value = parseFloat(this.value);
      this.value = value + delta;
      // round to nearest 10
      
      if (this.value < 0) {
        this.value = 0;
      }
      else if (this.value > 100) {
        this.value = 100;
      }
    },
    isNavigationKey(event) {
      const keyCode = this.getCharCodeFromEvent(event);
      return keyCode >= 37 && keyCode <= 40;
    },
    onFocusOut() {
      setTimeout(() => {
        if (!this.stillOnFocused) {
          this.params.api.stopEditing();
        }
      }, 0)
      this.stillOnFocused = false;
    },
    onFocus() {
      this.stillOnFocused = true;
    },

    moveUp(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex - 1;
        const colId = pColId;
        if (rowIndex < 0) {
          rowIndex = 0;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    },

    moveDown(api, pRowIndex, pColId) {
      setTimeout(() => {
        let rowIndex = pRowIndex + 1;
        const colId = pColId;
        if (api.getDisplayedRowAtIndex(rowIndex) == null) {
          rowIndex = this.params.rowIndex;
        }
        api.clearRangeSelection();
        api.setFocusedCell(rowIndex, colId, null);
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        });
      })
    }
  },

  created() {
    if (this.params.compactMode != null) {
      this.compactMode = this.params.compactMode;
    }
    
    this.setInitialState(this.params);

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart =
      this.params.charPress && '1234567890'.indexOf(this.params.charPress) < 0;

    
  },
  mounted() {
    Vue.nextTick(() => {
      // need to check if the input reference is still valid - if the edit was cancelled before it started there
      // wont be an editor component anymore
      if (this.$refs.input) {
        this.$refs.input.focus();
        if (this.highlightAllOnFocus) {
          this.$refs.input.select();

          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios: a) when user hits F2 and b)
          // when user hits a printable character, then on IE (and only IE) the caret
          // was placed after the first character, thus 'apply' would end up as 'pplea'
          const length = this.$refs.input.value
            ? this.$refs.input.value.length
            : 0;
          if (length > 0) {
            this.$refs.input.setSelectionRange(length, length);
          }
        }

        this.$refs.input.focus();
      }
    });
  }
})
</script>