

<template>
  <span>{{ label }}</span>
</template>

<script>
import Vue from 'vue';
import { setReadOnlyIfNotEditable } from './util.js';
export default Vue.extend({
  name: 'SkillLevelCellRenderer',
  data() {
    return {
      value: null,
    }
  },
  beforeMount() {
    setReadOnlyIfNotEditable(this.params);
    this.value = this.params && this.params.value !== -1? this.params.value: null;
  },
  computed: {
    label() {
      if (this.value != null) {
        const colId = this.params.column != null? this.params.column.colId: null
        if (colId != null) {
          return this.value[colId].data
        }
      }
      return ''
    }
  }
})
</script>
