<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @ok="modalOk"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <template v-if="exists">
          <div class="history-button lock-container">
            <template v-if="isLockVisible">
              <div class="ml-1 mr-1">{{ $t('lock') }}</div>
              <b-form-checkbox :disabled="isLockReadOnly" switch v-model="tag.readOnly"/>
            </template>
            <b-button variant="secondary" size="sm" @click="state.historyShow = true">
              <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
              {{ $t('button.history') }}
            </b-button>
          </div>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ 
          restrictedRequiredField != null
            ? $t('entity_selector.error.insufficient_permission_to_add_entity_with_reason', [$t('tag.title_singular').toLowerCase(), restrictedRequiredField])
            : $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('tag.title_singular').toLowerCase()])
          }}</span>
        </div>
      </template>
      <template v-else>

        <b-alert variant="danger" dismissible :show="errorShow" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
        </b-alert>
        
        <b-row>
          <b-col v-if="isNameVisible" cols="12">
            <b-form-group :label="$t('tag.field.name')" label-for="name" :class="{ 'mb-0': showNameError }">
              <b-input-group>
                <b-form-input id="name" type="text"
                  :data-vv-as="$t('tag.field.name')"
                  data-vv-name="tag.name"
                  data-vv-delay="500"
                  v-model="tag.name" 
                  v-validate="{ required: true }"
                  :readonly="isNameReadOnly"
                  :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'tag.name')"
                  autofocus trim>
                </b-form-input>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('tag.name') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['name'] != null">
            <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="tag[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="tag[field.name]" :componentId="componentId" :field="field" :disabled="isReadOnly || (exists && !canEdit(permissionName, [field.name]))"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
        </b-row>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="!isAccessDenied && canEdit()" :disabled="disableOk" size="sm" variant="success" @click="modalOk">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <template v-if="exists">
      <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="id" entityType="TAG" :customFields="customFields" />
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import { strRandom, processRegExp } from '@/helpers';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { tagService } from '@/services';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';

export default {
  name: 'TagModal',
  components: {
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    id:        { type: String,   default: `TAG_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: 'TAG',
      modelInfo: null,
      alertMsg: null,
      state: {
        editable:            false,
        isSubmitting:        false,
        modalShow:           false,
        autoScheduleExpanded:false,
        calShow:             false,
        historyShow:         false
      },
      tag: {
        uuId:               null,
        name:               null,
        description:        null,
        identifier:         null,
        readOnly:           false
      },
      isAccessDenied: false,
      
      customFields: [],
      customFieldMap: {},
      restrictedRequiredField: null
    }
  },
  created() {
    this.getModelInfo();
    if(this.id && this.id.indexOf('TAG_NEW_') == -1) {
      this.tagGet(this.id);
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.original = {
      readOnly: false
    }
    this.originTag = null;
  },
  mounted() {
    this.state.modalShow = this.show;
    if (this.show) {
      this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
    }
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originTag = null;
  },
  computed: {
    customFieldsFiltered() {
      return this.customFields.filter(f => this.canView(this.permissionName, [f.name]) && ((!this.exists && this.canAdd(this.permissionName, [f.name]))
      || this.exists));
    },
    componentId() {
      return `TAG_FORM_${this.id}`
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.tag.readOnly;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'tag.name');
    },
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.title? this.title : this.$t('tag.title_new');
    },
    exists() {
      return this.id && !this.id.startsWith('TAG_NEW_');
    },
    maxIdentifierLength() {
      const values = this.modelInfo === null ? [] : this.modelInfo.filter(info => {
        return info.field === "identifier";
      });
      return values.length !== 0 ? values[0].max : 200;
    },
    isNameVisible() {
      //Name is mandatory field so checking against canAdd() can be skipped
      return this.canView(this.permissionName, ['name'])
    },
    isNameReadOnly() {
      return this.isReadOnly || (this.exists && !this.canEdit(this.permissionName, ['name']))
    },
    disableOk() {
      return (this.original.readOnly && this.tag.readOnly) || this.state.isSubmitting;
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    }
  },
  watch: {
    show(newValue) {
      this.processWhenShowModal(newValue);
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      if(newValue != this.state.modalShow) {
        await getCustomFieldInfo(this, 'TAG');
        this.$validator.resume();
        if (this.customFields.length == 0) {
          this.customFieldMap = {};
        } else {
          this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields, this.allowViewFunc);
        }
        this.state.modalShow = newValue;
        this.state.autoScheduleExpanded = false;
        this.alertMsg = null;
        this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        this.restrictedRequiredField = null;
        if(this.id.indexOf('TAG_NEW_') === -1) {
          this.tagGet(this.id);
        } else {
          if (newValue) {
            const requiredFields = ['name']
            const requiredCustomFields = this.customFields.filter(i => i.notNull == true).map(i => i.name);
            if (requiredCustomFields.length > 0) {
              requiredFields.push(...requiredCustomFields);
            }
            let result = this.canView2(this.permissionName, requiredFields);
            if (result.val) {
              result = this.canAdd2(this.permissionName, requiredFields)
            } 
            
            if (result.restrictedProp != null) {
              this.restrictedRequiredField = this.getDisplayNameOfProperty(result.restrictedProp);
            }

            if (result.val) {
              this.isAccessDenied = false;
            } else {
              this.isAccessDenied = true;
            }
          } else {
            this.isAccessDenied = false;
          }
          this.resetTagProperties();
        }
      }
    },
    getDisplayNameOfProperty(val) {
      const found = this.customFields.find(i => i.name == val);
      if (found != null) {
        return found.displayName;
      }
      return  this.$t(`tag.field.${val}`);
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "TAG"}).then(value => {
        self.modelInfo = value.TAG.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    hasFieldValidationError(key) {
      return this.errors.first(key) && this.errors.first(key).length? true: false;
    },
    // Form field validation
    fieldStateValidate(ref) {
      if (!this.readOnly) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref)? null: false;
        }
      }
      return null;
    },
    fieldInvalidFeedback(ref) {
      const matchedErrors = this.errors.items.filter(i => i.field == ref);
      if(matchedErrors.length > 0) {
        return matchedErrors[0].msg;
      } else {
        return '';
      }
    },
    tagGet(id) {
      tagService.query([{ uuId: id }]).then(response => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if(data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      const s = this.tag;
      for (const key of Object.keys(s)) {
        s[key] = data[key] || null;
      }
      
      this.original.readOnly = data.readOnly;
      
      for (const field of this.customFields) {
        if (typeof data[field.name] !== 'undefined') {
          s[field.name] = data[field.name];
        }
      }
      
      this.originTag = cloneDeep(s);
    },
    modalOk() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.tag[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.errors.clear();
      //Cross field validation
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.tagSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    tagSubmit() {
      const data = cloneDeep(this.tag);
      
      let mode = 'update';
      if(this.id.indexOf('TAG_NEW_') !== -1) {
        mode = 'create';
        delete data['uuId'];
      }
      this.tagPost(mode, data, this.$t(`tag.${mode}`));
    },
    async tagPost(method, data, successMsg) {
      this.state.isSubmitting = true;

      //Skip updating tag if there is no change in tag properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.removeUnchangedTagProperties(data);
      }
      let result = null;
      if (method == 'create' || hasChanged) {
        result = await tagService[method]([data])
        .then(() => {
          return null;
        })
        .catch((e) => {
           return { hasError: true, error: e }
        });
      }

      if (result != null && result.hasError) {
        this.httpAjaxError(result.error);
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { msg: successMsg });
      }

      this.state.isSubmitting = false;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
        
      } else if (response && 422 === response.status) {
        let errorMsg = this.$t('error.attention_required');
        const feedback = response.data[response.data.jobCase][0];
        const clue = feedback.clue.trim().toLowerCase();
        if(['missing_argument','cannot_be_blank', 
            'string_limit_exceeded', 'number_limit_exceeded', 'not_unique_key'
            ].includes(clue)) {
          errorMsg = this.$t('error.attention_required');
          const fieldKey = `tag.${feedback.args[0]}`;
          const args = [this.$t(`tag.field.${feedback.args[0]}`)];
          let clueNotHandled = false;
          switch (clue) {
            case 'missing_argument': //Do nothing. Doesn't need additional argument
              break;
            case 'string_limit_exceeded':
            case 'number_limit_exceeded':
              args.push(feedback.args[1]);
              break;
            case 'not_unique_key':
              break;
            default:
              clueNotHandled = true;
              errorMsg = this.$('error.internal_server'); //reset the errorMsg to internal_server error.
          }
          if (!clueNotHandled) {
            this.errors.add({
              field: fieldKey,
              msg: this.$t(`error.${clue}`, args)
            });
          }
        }
        this.alertMsg = errorMsg;
      } else {
        this.alertMsg = this.$t('error.internal_server');
        console.error(e); // eslint-disable-line no-console
      }
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    resetTagProperties() {
      const keys = Object.keys(this.tag);
      this.errors.clear();
      this.$validator.reset();
      for(let i = 0, len = keys.length; i < len; i++) {
        
        let customField = this.customFields.find(f => f.name === keys[i])
        if (customField) {
          if (customField.def) {
            this.tag[keys[i]] = customField.def;
            continue;
          }
        }
        this.tag[keys[i]] = null;
      }
      this.originTag = null;
    },
    removeUnchangedTagProperties(data) {
      //Remove those properties whose value is not changed in provided data against original tag.
      //Assuming all properties are string type.
      //Property with data type other than string needs dedicated comparison logic.
      const originalTag = this.originTag;
      const keys = Object.keys(data).filter(i => i != 'uuId');
      let hasChanged = false;
      for (const key of keys) {
        if (originalTag[key] === data[key]) {
          delete data[key];
          continue;
        }
        if (!hasChanged) {
          hasChanged = true;
        }
      }
      return hasChanged;
    },
    allowViewFunc(fieldName) {
      return this.canView(this.permissionName, [fieldName]) 
              && ((!this.exists && this.canAdd(this.permissionName, [fieldName]) || this.exists));
    }
  }
}
</script>
