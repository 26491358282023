<template>
  <div class="edit-cell">
    <span>{{ label }}</span>
    <SkillLevelEditorModal :show.sync="show" :skillLevels="skillLevels" @ok="ok"/>
  </div>
</template>

<script>
import Vue from 'vue'
import * as moment from 'moment-timezone'
moment.tz.setDefault('Etc/UTC')

export default Vue.extend({
  name: 'SkillLevelEditor'
  , components: {
    SkillLevelEditorModal: () => import('@/components/modal/SkillLevelEditorModal')
  }
  , data() {
    return {
      show: true
      , value: null
      , skillLevels: []
    }
  }
  , watch: {
    show(newValue) {
      if(!newValue) {
        this.closePicker()
      }
    }
  }
  , methods: {
    /** Reserved methods START */
    getValue() {
      return this.value
    },
    isCancelBeforeStart() {
      return false
    },
    isCancelAfterEnd() {
      return false
    },
    /** Reserved methods END */

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing()
      }, 100)
    },
    ok(levels) {
      let hasChanged = false
      for (const level of levels) {
        const found = this.skillLevels.find(i => i.kind == level.kind && i.data == level.data)
        if (found == null) {
          hasChanged = true
          break
        }
      }

      if (hasChanged) {
        const newValue = {}
        for (const level of levels) {
          newValue[level.kind] = level
        }
        this.value = newValue
      }
      
      this.closePicker()
    },
    prepareValue() {
      if (this.params.value != null) {
        this.value = this.params.value
        const list = []
        for (const key in this.params.value) {
          list.push(this.params.value[key])
        }
        this.skillLevels.splice(0, this.skillLevels.length, ...list)
      } else {
        //Stop cell editing when there is no valid value
        this.closePicker()
      }
    }
  },
  created() {
    this.prepareValue()
  },
  mounted() {

  },
  computed: {
    label() {
      if (this.value != null) {
        const colId = this.params.column != null? this.params.column.colId: null
        if (colId != null) {
          return this.value[colId].value
        }
      }
      return ''
    }
  }
})
</script>