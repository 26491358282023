<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[componentId]"
      @ok="modalOk"
      @hidden="modalCancel"
      scrollable
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isAccessDenied">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ $t('entity_selector.error.insufficient_permission_to_add_entity', [$t('webhook.title').toLowerCase()]) }}</span>
        </div>
      </template>
      <template v-else>

        <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
          <ul :show="showErrorDetail" class="mb-0">
            <template v-for="(item, index) in alertMsgDetails">
              <li :key="index">{{ item }}</li>
            </template>
          </ul>
        </b-alert>

        <div class="container pl-0">
          <b-row>
            <b-col cols="6" class="pr-0">
              <b-form-group id="entity" :label="$t('webhook.field.entity')" label-for="entity">
                <select id="entityType" class="custom-select" v-model="webhook.entity" :disabled="isReadOnly">
                  <template v-for="(opt, index) in entityOptions">
                    <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                  </template>
                </select>
              </b-form-group>
            </b-col>
            <b-col cols="6" class="pr-0">
              <b-form-group id="action" :label="$t('webhook.field.action')" label-for="action">
                <select id="actionType" class="custom-select" v-model="webhook.action" :disabled="isReadOnly">
                  <template v-for="(opt, index) in actionOptions">
                    <option :value="opt.value" :key="index" :style="{ display: opt.num < 0? 'none': 'block' }">{{ opt.text }}</option>
                  </template>
                </select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="pr-0">
              <b-form-group :label="$t('webhook.field.url')" label-for="url" :class="{ 'mb-0': showUrlError }">
                <b-input-group>
                  <b-form-input id="url" type="text"
                    :data-vv-as="$t('webhook.field.url')"
                    data-vv-name="webhook.url"
                    v-model="webhook.url" 
                    v-validate="{ required: true}"
                    :state="fieldValidateUtil.stateValidate(isReadOnly, veeFields, errors, 'webhook.url')"
                    :readonly="isReadOnly"
                    trim>
                  </b-form-input>
                </b-input-group>
                <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showUrlError }">
                  <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('webhook.url') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button v-if="!isAccessDenied && !isReadOnly && ((exists && canEdit()) || (!exists && canAdd()))" size="sm" variant="success" @click="modalOk">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { webhookService } from '@/services';
import { webhookActions } from '@/selectOptions/webhookActions';
import { removeDeniedProperties } from '@/views/management/script/common';

export default {
  name: 'WebhookModal',
  components: {
  },
  props: {
    id:        { type: String,   default: `WEBHOOK_NEW_${strRandom(5)}` },
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    show:      { type: Boolean, required: true }
  },
  data() {
    return {
      permissionName: 'WEBHOOK',
      modelInfo: null,
      alertMsg: null,
      alertMsgDetails: [],
      settings: {},
      state: {
        editable:     false,
        isSubmitting: false,
        modalShow:    false,
        confirmUnsavedChangeShow: false,
        historyShow:      false,
      },
      webhook: {
        uuId: null,
        entity:   null,
        action: null,
        url: null,
      },
      isAccessDenied: false,
      entityOptions: []
    }
  },
  created() {
    this.getModelInfo();
    this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
    if(this.id && this.id.indexOf('WEBHOOK_NEW_') == -1) {
      this.webhookGet(this.id);
    }
    this.fieldValidateUtil = fieldValidateUtil;
    this.originWebhook = null;
    this.mandatoryFields = ['entity', 'action', 'url']
    // this.getModelInfo();
    // if(this.exists) {
    //   // this.projectGet(this.id);
    //   this.webhookGet(this.id);
    // }
    // this.fieldValidateUtil = fieldValidateUtil;
    
    // //Disable validation to prevent js error raised by 'required' validation.
    // //It will be enabled when show state is true.
    // this.$validator.pause();

    this.actionOptions = webhookActions(this);
  },
  mounted() {
    // this.loadViewProfile;
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originWebhook = null;
    this.actionOptions = null;
  },
  computed: {
    componentId() {
      return `WEBHOOK_FORM_${this.id}`;
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly;
    },
    showUrlError() {
      return fieldValidateUtil.hasError(this.errors, 'webhook.url');
    },
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    exists() {
      return this.id && !this.id.startsWith('WEBHOOK_NEW_');
    },
    labelTitle() {
      return this.title != null? this.title : (this.id != null? this.$t('webhook.title_detail') : this.$t('webhook.title_new'));
    },
  },
  watch: {
    async show(newValue) {
      if(newValue != this.state.modalShow) {
        this.$validator.resume();
        this.state.modalShow = newValue;
        this.alertMsg = null;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        if(this.id.indexOf('WEBHOOK_NEW_') === -1) {
          this.webhookGet(this.id);
        } else {
          if (newValue && !(this.canView(this.permissionName, ['name']) && this.canAdd(this.permissionName, ['name']))) {
            this.isAccessDenied = true;
          } else {
            this.isAccessDenied = false;
          }
          this.processWhenShowModal();
        }
      }
    }
  },
  methods: {
    async processWhenShowModal() {
      this.resetProperties();
      this.alertMsg = null;
      this.alertError = false;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      this.state.editable =  (!this.exists && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
    },
    resetProperties() {
      this.errors.clear();
      this.$validator.reset();
      this.webhook.uuId = null;
      this.webhook.entity = null;
      this.webhook.action = null;
      this.webhook.url = null;
      this.originWebhook = null;
    },
    getModelInfo() {
      this.$store.dispatch('data/info', {type: "webhook", object: "MODELS"}).then(value => {
        this.entityOptions.splice(0, this.entityOptions.length);
        for(const v of value) {
          this.entityOptions.push({ value: v, text: v });
        }
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    fieldStateValidate(ref) {
      if (!this.readOnly) {
        if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
          return !this.errors.has(ref)? null: false;
        }
      }
      return null;
    },
    webhookGet(id) {
      webhookService.get([{ uuId: id}]).then((response) => {
        const listName = response.data.jobCase;
        const data = response.data[listName] || [];
        if (data.length > 0) {
          this.digestResponse(data[0]);
        }
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.isAccessDenied = true;
          return;
        }
        this.httpAjaxError(e);
      });
    },
    digestResponse(data) {
      this.$validator.pause();
      const w = this.webhook;
      w.uuId = data.uuId;
      w.entity = data.entity;
      w.action = data.action;
      w.url = data.url;
      this.originWebhook = cloneDeep(w);

      this.$validator.resume();
    },
    httpAjaxError(e) {
      const response = e.response;
      let errorMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        errorMsg = this.$t('error.authorize_action');
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if(['Missing_argument','Cannot_be_blank', 'Property_limit_exceeded'].includes(feedback.clue)) {
          errorMsg = this.$t(`error.cannot_be_blank`, [feedback.args[0]]);
        } else if ('Failed_api' == feedback.clue) {
          errorMsg =  this.$t('webhook.error.failed_api');
          if (feedback.hint != null && feedback.hint.includes('is conflicted with other existed webhook definition')) {
            errorMsg = this.$t('webhook.error.failed_to_update_conflict_definition');
          }
        } else if ('Invalid_value' == feedback.clue) {
          if (Array.isArray(feedback.args) && feedback.args.length > 1) {
            errorMsg = this.$t('error.attention_required');
            this.errors.add({
              field: `webhook.${feedback.args[0]}`,
              msg: this.$i18n.t('error.invalid_value', [this.$i18n.t(`webhook.field.${feedback.args[0]}`)])
            });  
          } else {
            errorMsg = this.$t('error.invalid_value', feedback.args);
          }
        }
      } else if (response?.status === 503) {
        const jobClue = response.data.jobClue;
        if ('Not_available' === jobClue.clue) {
          errorMsg = this.$t('webhook.error.service_unavailable');
        }
      }
      this.alertMsg = errorMsg;
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.componentId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    confirmUnsavedChangeOk() {
      this.state.confirmUnsavedChangeShow = false;
      this.scheduleRedirect();
    },
    async updateWebhook(method, data) {
      const result = {
        hasError: false,
        msg: this.$t(`webhook.${method}`)
      }
      removeDeniedProperties(this.permissionName, data, this.exists? 'EDIT':'ADD');
      let webhookId = await webhookService[method]([data])
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      }).catch(e => {
        result.hasError = true;
        result.error = e;
        result.msg = this.$t(`webhook.error.failed_to_${method}`);
        return result;
      });

      if (result != null && (result.hasError || result.uuId == null)) {
        return result;
      } 

      result.webhookId = webhookId;
      return result;
    },
    async modalOk() {
      this.errors.clear();

      //Pre-check on health status is provided
      const status = await this.$store.dispatch('data/status').then(value => {
        return (value?.webhookEnabled != true || value?.webhookHealthCheckEnabled != true) 
          || (value?.webhookEnabled === true 
                && value?.webhookHealthCheckEnabled === true 
                && value?.webhookStatus?.status === 'UP');
      })

      if (!status) {
        this.alertMsg = this.$t('webhook.error.service_unavailable');
        this.scrollToTop();
        return;
      }

      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
          this.webhookSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
    },
    webhookSubmit() {
      const data = cloneDeep(this.webhook);

      let method = 'update';
      if(this.id.indexOf('WEBHOOK_NEW_') !== -1) {
        method = 'create';
        delete data['uuId'];
      }
      this.webhookPost(method, data, this.$t(`webhook.${method}`));
    },
    async webhookPost(method, data, successMsg) {
      this.state.isSubmitting = true;
      let webhookId = null;
      let result = null;

      //Skip updating webhook if there is no change in webhook properties.
      let hasChanged = false;
      if (method != 'create') {
        hasChanged = this.hasWebhookPropertiesChanged(data);
      }

      //Filter out properties which in denyRules
      const propKeys = Object.keys(data);
      const permissionName = method == 'create'? 'WEBHOOK__ADD' : 'WEBHOOK__EDIT';
      const permList = this.$store.state.authentication.user.permissionList.filter(f => f.name === permissionName);
      const perms = permList.length > 0 ? permList[0] : [];
      const denyRules = perms && perms.permissionLink && perms.permissionLink.denyRules ?
                        perms.permissionLink.denyRules : [];
      for (const k of propKeys) {
        if (denyRules.includes(k)) {
          delete data[k];
        }
      }
      if (method == 'create' || hasChanged) {
        result = await this.updateWebhook(method, data);
        webhookId = result.webhookId;
      } else {
        webhookId = data.uuId;
      }

      if (result != null && result.hasError) {
        this.httpAjaxError(result.error);
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { msg: successMsg, uuId: webhookId });
      }

      this.state.isSubmitting = false;
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false)
    },
    hasWebhookPropertiesChanged(data) {
    //Return true if any properties have changed
    const originalWebhook = this.originWebhook;
    const keys = Object.keys(data).filter(i => i != 'uuId');
    let hasChanged = false;
    for (const key of keys) {
      if (originalWebhook[key] === data[key]) {
        continue;
      }
      if (!hasChanged) {
        hasChanged = true;
      }
    }
    return hasChanged;
  },
  }
}
</script>

<style lang="scss" scoped>

</style>