<template>
  <StageBadgeGroupModal :show.sync="modalShow" :stageList="stageList" 
    :tagFilter="tagFilter"
    @ok="stageSelectorOk" 
    @cancel="stageSelectorCancel" 
    @stageDeleted="stageSelectorDeleted"
  />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'StatusEditor',
  components: {
    StageBadgeGroupModal: () => import('@/components/modal/StageBadgeGroupModal')
  },
  data() {
    return {
      value: ''
      , modalShow: false
      , stageList: []
      , filterEntity: null
      , filterTags: []
    };
  },
  computed: {
    tagFilter() {
      return { entity: this.filterEntity, tags: this.filterTags }
    }
  },
  methods: {
    getValue() {
      return this.value
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    stageSelectorOk(modifiedStageList) {

      if (modifiedStageList.length == 0 && (this.params.value == null || this.params.value.id == null)) {
        this.closePicker()
        return
      }
      
      if (this.params.value.id == null && modifiedStageList.length > 0) {
        this.value = { id: modifiedStageList[0].uuId, name: modifiedStageList[0].name }
        this.closePicker()
        return
      }

      if (this.params.value.id != null && modifiedStageList.length == 0) {
        this.value = { id: null, name: null }
        this.closePicker()
        return
      }

      //Check UuId
      if (this.params.value.id != modifiedStageList[0].uuId) {
        this.value = { id: modifiedStageList[0].uuId, name: modifiedStageList[0].name }
      }
      this.closePicker;
    },
    stageSelectorCancel({ modifiedList=[]}={}) {
      if (modifiedList.length > 0 && this.value != null && this.value.id != null) {
        const updatedList = this.modifiedList.filter(i => i.action == 'UPDATE')
        let matchedObj = updatedList.find(i => i.uuId == this.value.id)
        if (matchedObj != null) {
          this.value.name = matchedObj.name
        }
      }
      this.closePicker()
    },
    prepareStageList() {
      if(this.value != null && this.value.id != null) {
        this.stageList.push({ uuId: this.value.id, name: this.value.name })
      }
    },
    stageSelectorDeleted(/**{ removedList=[]}={} */) {
      
    }
    
  },

  created() {
    if (this.params.filterEntity != null && typeof this.params.filterEntity === 'string') {
      this.filterEntity = this.params.filterEntity;
    } 
    if (this.params.filterTagProperty != null 
        && this.params.data != null && Array.isArray(this.params.data[this.params.filterTagProperty])
        && this.params.data[this.params.filterTagProperty].length > 0
    ) {
      this.filterTags.splice(0, this.filterTags.length, ...this.params.data[this.params.filterTagProperty]);
    }
    this.modalShow = true;
    this.value = this.params.value;
    this.prepareStageList();
  }
})
</script>