var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ProjectBadgeGroupModal", {
    attrs: { show: _vm.modalShow, projectList: _vm.projectList },
    on: {
      "update:show": function ($event) {
        _vm.modalShow = $event
      },
      ok: _vm.projectSelectorOk,
      cancel: _vm.projectSelectorCancel,
      projectDeleted: _vm.projectSelectorDeleted,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }